import React, { Component } from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/Home/Home";
import Features from "./components/Features/Features";
import Beats from "./components/Beats/Beats";
import Producers from "./components/Producers/Producers";
import Footnote from "./components/Footnote/Footnote";
import MusicPlayer from "./components/MusicPlayer/MusicPlayer";

class App extends Component {
  state = {
    showPopUp: false,
    errorMsg: "",
    sideBarOpen: false,
    phone: "",
    message: "Send",
  };
  handleOnChange = (value) => {
    console.log(value);
    this.setState({ phone: value, showPopUp: false, message: "Send" });
  };

  submitHandler = (e) => {
    this.setState({
      showPopUp: true,
      message: "Sent!",
    });
  };

  sideBarToggleHandler = () => {
    console.log("clicked");
    this.setState((prevState) => {
      return { sideBarOpen: !prevState.sideBarOpen };
    });
  };

  blackDropClickHandler = () => {
    this.setState({ sideBarOpen: false, showPopUp: false });
  };

  popUpHandler = () => {
    this.setState((prevState) => {
      return { showPopUp: !prevState.showPopUp };
    });
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <React.Fragment>
                <Home
                  id="Home"
                  sideBarBtn={this.sideBarToggleHandler}
                  showSideBar={this.state.sideBarOpen}
                  open={this.state.sideBarOpen}
                  submit={this.submitHandler}
                  closeBG={this.blackDropClickHandler}
                  handleOnChange={this.handleOnChange}
                  phone={this.state.phone}
                  popUpHandler={this.popUpHandler}
                  showPopUp={this.state.showPopUp}
                  message={this.state.message}
                />
                <Features />
                <Beats />
                <Producers />
                <Footnote
                  message={this.state.message}
                  submit={this.submitHandler}
                  closeBG={this.blackDropClickHandler}
                  handleOnChange={this.handleOnChange}
                  phone={this.state.phone}
                  popUpHandler={this.popUpHandler}
                  showPopUp={this.state.showPopUp}
                />
                {/* <MusicPlayer
                  sideBarBtn={this.sideBarToggleHandler}
                  showSideBar={this.state.sideBarOpen}
                  open={this.state.sideBarOpen}
                  submit={this.submitHandler}
                  closeBG={this.blackDropClickHandler}
                  handleOnChange={this.handleOnChange}
                  phone={this.state.phone}
                  popUpHandler={this.popUpHandler}
                  showPopUp={this.state.showPopUp}
                  message={this.state.message}
                /> */}
              </React.Fragment>
            )}
          />
          <Route
            path="/Musicplayer"
            render={(props) => (
              <React.Fragment>
                <MusicPlayer
                  sideBarBtn={this.sideBarToggleHandler}
                  showSideBar={this.state.sideBarOpen}
                  open={this.state.sideBarOpen}
                  submit={this.submitHandler}
                  closeBG={this.blackDropClickHandler}
                  handleOnChange={this.handleOnChange}
                  phone={this.state.phone}
                  popUpHandler={this.popUpHandler}
                  showPopUp={this.state.showPopUp}
                  message={this.state.message}
                />
              </React.Fragment>
            )}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
