import React from "react";
import styles from "./Producers.module.css";
import Iphone from "../../assets/img/beats_page 1.png";

const Producers = (props) => (
  <div className={styles.Producers} id="Producers">
    <div className={styles.Producers_title}>PRODUCERS</div>
    <div className={styles.Producers_body}>
      <div className={styles.prod_box}>
        <h3>MAKE BEATS?</h3>
        <h6>We'd love to hear from you.</h6>
        <p>
          Our producers are the core of BeatStyle. Your music fuels the
          creativity of thousands of users on our platform. Visit our interest form to get started.
        </p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc3U4ZKRLSe4Ejhq8BMV7oqZpyHW6-leqmhhTpjiEM9-ZJUVw/viewform" className={styles.prod_portal_btn}>
          Producer Interest Form
          <div />
          <div></div>
        </a>
      </div>
      <img
        className={styles.prod_iphone}
        src={Iphone}
        alt="iphone beatstyle producer"
      />
      <div className={styles.MOBILE_prod_btn}>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc3U4ZKRLSe4Ejhq8BMV7oqZpyHW6-leqmhhTpjiEM9-ZJUVw/viewform" className={styles.prod_portal_btn}>
          Producer Interest Form
          <div />
          <div></div>
        </a>
      </div>
    </div>
  </div>
);

export default Producers;
