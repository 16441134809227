import React from "react";
import styles from "./Footnote.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-scroll";

import FacebookLogo from "../../assets/img/facebook_logo.png";
import TwitterLogo from "../../assets/img/twitter_logo.png";
import InstagramLogo from "../../assets/img/instagram_logo.png";

const Footnote = props => {
  let btn_style = {
    background: "linear-gradient(113.75deg, #4b21b0 0%, #1f006a 100%)"
  };
  if (props.message === "Sent!") {
    btn_style.background =
      "linear-gradient(100.69deg, #0d7b18 0%, #074a0d 100%)";
  }
  return (
    <div className={styles.Footnote} id="Footnote">
      <div className={styles.Footnote_Contact}>
        <p>Text me a link</p>
        <form className={styles.txt_link}>
          <PhoneInput
            disableAreaCodes={true}
            country={"us"}
            value={props.phone}
            onChange={props.handleOnChange}
            inputStyle={{ width: "100%" }}
            containerStyle={{ marginRight: "2%" }}
          />
          <button
            id="phoneNumberBtn"
            type="button"
            className={styles.send_btn}
            onClick={props.submit}
            style={btn_style}
          >
            {props.message}
          </button>
        </form>
        <p>Social Media</p>
        <div className={styles.Footnote_Socials}>
          <a href="https://www.facebook.com/BeatStyleApp/">
            {" "}
            <img
              src={FacebookLogo}
              alt="beatstyle social media facebook"
            />{" "}
          </a>

          <a href="https://twitter.com/beatstyleapp?lang=en">
            {" "}
            <img src={TwitterLogo} alt="beatstyle social media twitter" />{" "}
          </a>
          <a href="https://www.instagram.com/beatstyleapp/">
            {" "}
            <img
              src={InstagramLogo}
              alt="beatstyle social media instagram"
            />{" "}
          </a>
        </div>
        <p className={styles.LLC}>
          <strong>(C) FreeFlows LLC</strong>{" "}
        </p>
      </div>
      <div className={styles.Footnote_Other}>
        <p>
          <strong>Other Links</strong>
        </p>
        <ul>
          <li>
            <a href="https://apps.apple.com/us/app/beatstyle-explore-beats-rap/id1379924613">
              Download on iPhone
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeuVO5iqBjybRC9vppQ61ZizK7S-90C5ece3zRdc6OTrJ4Y0g/viewform">Join Android waitlist</a>
          </li>
          <li>
            <a href="#">Producer Portal (Beta)</a>
          </li>
          <li>
            <a href="privacy.html">Privacy Policy</a>
          </li>
          <li>
            <a href="terms.html">Terms of Service</a>
          </li>
        </ul>
      </div>
      <div className={styles.Footnote_Menu}>
        <p>
          <strong>Menu</strong>
        </p>
        <ul>
          <li onClick={props.clicked}>
            <Link
              to="Features"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              {" "}
              Features
            </Link>
          </li>
          <li>
            <Link
              to="Beats"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              Beats
            </Link>
          </li>
          <li>
            <Link
              to="Producers"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              Producers
            </Link>
          </li>
          {/* <li>ABOUT US</li> */}
          <li>
            <Link
              to="Footnote"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              Contact
            </Link>
          </li>
        </ul>
        <p className={styles.MOBILE_LLC}>
          <strong>(C) FreeFlows LLC</strong>
        </p>
      </div>
    </div>
  );
};

export default Footnote;
