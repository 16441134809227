import React from "react";
import styles from "./DropToggleButton.module.css";

const dropToggleButton = props => (
  <button className={styles.toggle_btn} onClick={props.click}>
    <div className={styles.toggle_btn_line} />
    <div className={styles.toggle_btn_line} />
    <div className={styles.toggle_btn_line} />
  </button>
);

export default dropToggleButton;
