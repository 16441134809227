import React, { useRef } from "react";

import Play from "../CustomAudioPlayer/Play";
import Pause from "../CustomAudioPlayer/Pause";
import Bar from "../CustomAudioPlayer/Bar";

import useAudioPlayer from "../CustomAudioPlayer/useAudioPlayer";
import "../CustomAudioPlayer/Main.scss";

import { useEffect, useState } from "react";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Blackdrop from "../BlackdropBlur/BlackdropBlur";
import Pic from "../../assets/img/2cupshorty.png";
import Mm3 from "../../assets/music/doesntknow.mp3";
import styles from "./MusicPlayer.module.css";

var config = {
  apiKey: "AIzaSyBhnkgAgZLvBLuST45VDltLrfKMRyDJs20",
  authDomain: "beats-72620.firebaseapp.com",
  databaseURL: "https://beats-72620.firebaseio.com",
  projectId: "beats-72620",
  storageBucket: "beats-72620.appspot.com",
  messagingSenderId: "159704979567",
  appId: "1:159704979567:web:3e9d64d19ea0a26a3fd59a",
  measurementId: "G-B8RR2HLZ5N",
};

firebase.initializeApp(config);

function Audio(props) {
  const {
    curTime,
    duration,
    none,
    setPlaying,
    setClickedTime,
  } = useAudioPlayer();

  //const audio = document.getElementById("audio");
  const audio = useRef();
  const [inputState, setBeat] = useState(null);
  const [playing, setPlay] = useState(false);

  const playClick = () => {
    audio.current.play();
    setPlay(true);
  };

  const pauseClick = () => {
    audio.current.pause();
    setPlay(false);
  };

  useEffect(() => {
    let storage = firebase.storage();
    let storageRef = storage.ref();
    // let beatPathRef = storage.ref("doesntknow.mp3");
    // let GS_beatRef = storage.refFromURL(
    //   "gs://beats-72620.appspot.com/doesntknow.mp3"
    // );
    // let httpsRef = storage.refFromURL(
    //   "https://firebasestorage.googleapis.com/v0/b/beats-72620.appspot.com/o/doesntknow.mp3?alt=media&token=f9a53f72-88ad-4e54-9a17-530a4ee293dc"
    // );
    storageRef
      .child("doesntknow.mp3")
      .getDownloadURL()
      .then(function (url) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
          var blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();
        console.log("Im uploading the audio");
        setBeat(url);
      })
      .catch(function (error) {
        // Handle any errors
      });
  }, []);
  let blackDrop;
  if (props.open) {
    blackDrop = <Blackdrop click={props.closeBG} />;
  }

  return (
    <div className={styles.MusicPlayer}>
      <div
        className={styles.MusicPlayer_Background}
        style={{
          zIndex: "-1",
          position: "absolute",
          left: "0",
          top: "0",
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${Pic})`,
          filter: "blur(.8rem)",
        }}
      />
      <Navbar sideBarClickHandler={props.sideBarBtn} show={props.showPopUp} />
      <Sidebar show={props.showSideBar} clicked={props.closeBG} />;{blackDrop}
      <div className={styles.MusicPlayer_body}>
        <div className={styles.Beats_info}>
          <h3 style={{ marginTop: "-7%", marginBottom: "10%" }}>Now Playing</h3>
          <div className={styles.album_ctn}>
            <img
              src={Pic}
              alt="alt text input"
              className={styles.album_artwork}
            />
          </div>
          <h3 style={{ marginTop: "9%", marginBottom: "0" }}>Title</h3>
          <h3>Artist</h3>
        </div>

        <div className="player" style={{ width: "100vw" }}>
          {inputState ? (
            <React.Fragment>
              <audio id="audio" ref={audio}>
                <source src={inputState} />
                Your browser does not support the <code>audio</code> element.
              </audio>

              <div className="controls">
                {playing ? (
                  <Pause handleClick={pauseClick} />
                ) : (
                  <Play handleClick={playClick} />
                )}
                <Bar
                  curTime={curTime}
                  duration={duration}
                  onTimeUpdate={(time) => setClickedTime(time)}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="loader">Loading...</div>
          )}
          {/* {The below audio player is not shown, used only for update} */}
          <div style={{ display: "none" }}>
            <audio id="audio">
              <source src={inputState} />
              Your browser does not support the <code>audio</code> element.
            </audio>

            <div className="controls">
              {playing ? (
                <Pause handleClick={() => setPlaying(false)} />
              ) : (
                <Play handleClick={() => setPlaying(true)} />
              )}
              <Bar
                curTime={curTime}
                duration={duration}
                onTimeUpdate={(time) => setClickedTime(time)}
              />
            </div>
          </div>
          {/* {The above audio player is not shown, used only for update} */}
        </div>
        <a href="https://apps.apple.com/us/app/beatstyle-explore-beats-rap/id1379924613">
          RECORD
        </a>
      </div>
    </div>
  );
}

export default Audio;
