import React from "react";
import styles from "./Features.module.css";
import Browse from "../../assets/img/browse_page.png";
import Studio from "../../assets/img/studio_page.png";
import Social from "../../assets/img/social_feed.png";

const DATA = [
  {
    key: "1",
    style1: "purp_box_1",
    style2: "purp_box_2",
    img: Browse,
    alt: "beatstyle browse beats iphone",
    p1: "Discover beats",
    p2: "however you want.",
    p3: "We heard you",
    p4: "studio features on the way.",
    mobileText: "Browse"
  },

  {
    key: "2",
    style1: "white_box_1",
    style2: "white_box_2",
    img: Studio,
    alt: "beatstyle studio iphone",
    p1: "Record a track",
    p2: "with our simple and intuitive studio",
    p3: "Fresh content",
    p4: "every week",
    mobileText: "Record"
  },
  {
    key: "3",
    style1: "purp_box_1",
    style2: "purp_box_2",
    img: Social,
    alt: "beatstyle social iphone",
    p1: "Share anywhere",
    p2: "with your friends and community.",
    p3: "Talent discovery",
    p4: "at your fingertips.",
    mobileText: "Share"
  }
];

const features = DATA.map(sec => {
  return (
    <section key={sec.key} className={styles.Features_section}>
      <div className={[styles[sec.style1], styles.box].join(" ")}>
        <p className={styles.bold}>{sec.p1}</p>
        <p>{sec.p2}.</p>
      </div>

      <img src={sec.img} alt={sec.alt} className={styles.iphone_1} />

      <div className={[styles[sec.style2], styles.box].join(" ")}>
        <p className={styles.bold}>{sec.p3}</p>
        <p>{sec.p4}</p>
      </div>
      <div className={styles.mobileText}>{sec.mobileText}</div>
    </section>
  );
});

const Features = () => {
  return (
    <div className={styles.Features} id="Features">
      <div className={styles.Features_title}>FEATURES</div>
      <div className={styles.Features_body}>{features}</div>
    </div>
  );
};

export default Features;
