import React from "react";
import DropToggleButton from "../DropList/DropToggleButton";
import styles from "./Navbar.module.css";
import Logo from "../../assets/img/beatstyle-logo.png";

import { Link } from "react-scroll";

const navbar = (props) => (
  <header className={styles.navbar}>
    <nav className={styles.navbar_head}>
      <div className={styles.navbar_logo}>
        <div className={styles.navbar_toggle}>
          <DropToggleButton click={props.sideBarClickHandler} />
        </div>

        <div className={styles.navbar_title}>
          <img src={Logo} alt="BeatStyle logo" /> BEATSTYLE
        </div>
        <div className={styles.logo_left}>
          <img src={Logo} alt="BeatStyle logo" />
        </div>
      </div>

      <div className={styles.push} />

      <div className={styles.navbar_items}>
        <ul>
          <li>
            <Link
              to="Features"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              {" "}
              FEATURES
            </Link>
          </li>
          <li>
            <Link
              to="Beats"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              BEATS
            </Link>
          </li>
          <li>
            <Link
              to="Producers"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              PRODUCERS
            </Link>
          </li>
          <li>
            <Link
              to="Footnote"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
);
export default navbar;
