import React from "react";
import styles from "./Sidebar.module.css";
import { Link } from "react-scroll";

const sidebar = (props) => {
  let listClasses = [styles.sidebar];
  if (props.show) {
    listClasses = [styles.sidebar_open, styles.sidebar];
  }

  return (
    <nav className={listClasses.join(" ")}>
      <button
        onClick={props.clicked}
        type="button"
        className={styles.sidebar_close}
      />
      <ul className={styles.side_menu}>
        <li>
          <Link
            to="Features"
            activeClass="active"
            spy={true}
            smooth={true}
            duration={500}
            onClick={props.clicked}
          >
            {" "}
            FEATURES
          </Link>
        </li>
        <li>
          <Link
            to="Beats"
            activeClass="active"
            spy={true}
            smooth={true}
            duration={500}
            onClick={props.clicked}
          >
            BEATS
          </Link>
        </li>
        <li>
          <Link
            to="Producers"
            activeClass="active"
            spy={true}
            smooth={true}
            duration={500}
            onClick={props.clicked}
          >
            PRODUCERS
          </Link>
        </li>
        <li>
          <Link
            to="Footnote"
            activeClass="active"
            spy={true}
            smooth={true}
            duration={500}
            onClick={props.clicked}
          >
            CONTACT
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default sidebar;
