import React, { Component } from "react";
import styles from "./Beats.module.css";
import Beat_1 from "../../assets/img/beat_1.png";
import TwoCup from "../../assets/img/2cupshorty.png";
import JDM from "../../assets/img/JDM_beats1.png";
import Bocce from "../../assets/img/bocce_beats1.png";
import FourZeroEight from "../../assets/img/408.jpeg";
import DJReadyRoc from "../../assets/img/Images_DJReadyRoc.jpeg";
import JTak from "../../assets/img/Images_J-Tak.jpeg";
import JDAWA from "../../assets/img/Images_JDAWA.jpeg";
import RyanReents from "../../assets/img/Images_RyanReents.jpeg";
import PrimeOne from "../../assets/img/PrimeOne.jpeg";
import img1 from "../../assets/img/beat1.jpg";
import img2 from "../../assets/img/beat2.jpg";
import img3 from "../../assets/img/beat3.jpg";
import img4 from "../../assets/img/beat4.jpg";

import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const BEATS = [
  "https://firebasestorage.googleapis.com/v0/b/beatstyle-website.appspot.com/o/Fast%20Lane.mp3?alt=media&token=55e84bfc-1092-4108-a5d3-9adfb8ab2ab8",
  "https://firebasestorage.googleapis.com/v0/b/beatstyle-website.appspot.com/o/Indi%20Harmonix.mp3?alt=media&token=05d1e4ab-b59c-4116-b600-6c1012758912",
  "https://firebasestorage.googleapis.com/v0/b/beatstyle-website.appspot.com/o/Mystic.mp3?alt=media&token=5cb1a32b-3e91-423a-a5dc-6a2dceb5165d",
  "https://firebasestorage.googleapis.com/v0/b/beatstyle-website.appspot.com/o/Pierre.mp3?alt=media&token=6002b791-0d67-41e8-9787-c4ece3cc39f8",
  "https://firebasestorage.googleapis.com/v0/b/beatstyle-website.appspot.com/o/Wizardry.mp3?alt=media&token=a088b243-a896-4c33-a2b5-e5ec8455d4d6",
];

const DATA_BEATS = [
  {
    key: 0,
    img: img1,
    src: BEATS[0],
    alt: "artist beatstyle beats",
    name: "Fast Lane",
    artist: "Artist 0",
  },
  {
    key: 1,
    img: img2,
    src: BEATS[1],
    alt: "artist beatstyle beats",
    name: "Indi Harmonix",
    artist: "Artist 1",
  },
  {
    key: 2,
    img: img3,
    src: BEATS[2],
    alt: "artist beatstyle beats",
    name: "Mystic",
    artist: "Artist 2",
  },
  {
    key: 3,
    img: img4,
    src: BEATS[3],
    alt: "artist beatstyle beats",
    name: "Pierre",
    artist: "Artist 3",
  },
  {
    key: 4,
    img: Beat_1,
    src: BEATS[4],
    alt: "artist beatstyle beats",
    name: "Wizardry",
    artist: "Artist 4",
  },
];

const DATA_PRODUCER = [
  {
    key: 0,
    img: TwoCup,
    name: "2cupshorty",
    alt: "2cupshorty beatstyle producer",
  },
  {
    key: 1,
    img: Bocce,
    name: "Bocce Beats",
    alt: "bocce beats beatstyle producer",
  },
  {
    key: 2,
    img: JDM,
    name: "JDM",
    alt: "JDM beatstyle producer",
  },
  {
    key: 3,
    img: FourZeroEight,
    name: "408",
    alt: "408 beatstyle producer",
  },
  {
    key: 4,
    img: RyanReents,
    name: "Ryan Reents",
    alt: "RyanReents beatstyle producer",
  },
  {
    key: 5,
    img: PrimeOne,
    name: "Prime One",
    alt: "Prime One beatstyle producer",
  },
  {
    key: 6,
    img: JDAWA,
    name: "JDAWA",
    alt: "JDAWA beatstyle producer",
  },
  {
    key: 7,
    img: JTak,
    name: "J-Tak",
    alt: "J-Tak beatstyle producer",
  },
  {
    key: 8,
    img: DJReadyRoc,
    name: "DJ ReadyRoc",
    alt: "DJReadyRoc beatstyle producer",
  }
];

class Beats extends Component {
  state = {
    beat: 0,
    isPlaying: false,
    curr: null,
  };
  audioOnClick = () => {
    console.log("in aido");
    if (!this.refs.audio.paused) {
      this.setState({ isPlaying: true });
    } else {
      this.setState({ isPlaying: false });
    }
  };
  addArrowClickHandler = (direction) => {
    const currBeat = this.state.beat;
    if (direction === "r") {
      if (currBeat === null) {
        console.log("false");
        this.setState({ beat: 0, isPlaying: true });
        this.refs.audio.load();
        this.refs.audio.play();
        return;
      }
      if (currBeat < 4) {
        this.setState({ isPlaying: true });
        const nextBeat = parseInt(currBeat) + 1;
        this.setState({ beat: nextBeat });
        this.refs.audio.pause();
        this.refs.audio.load();
        this.refs.audio.play();
      } else {
        this.setState({ isPlaying: false });
        this.refs.audio.pause();
        this.setState({ beat: null });
      }
    } else {
      if (currBeat === null) {
        this.setState({ beat: 4, isPlaying: true });
        this.refs.audio.load();
        this.refs.audio.play();
        return;
      }
      if (currBeat === 0) {
        this.setState({ isPlaying: false });
        this.refs.audio.pause();
        this.setState({ beat: null });
        return;
      }
      if (currBeat < 5) {
        this.setState({ isPlaying: true });
        const nextBeat = parseInt(currBeat) - 1;
        this.setState({ beat: nextBeat });
        this.refs.audio.pause();
        this.refs.audio.load();
        this.refs.audio.play();
      } else {
      }
    }
  };
  keepDirectionWhenDragging = () => {};

  beatPlayerHandler = (id) => {
    if (this.state.isPlaying && this.state.beat === id) {
      this.setState({ isPlaying: false }, function () {
        this.refs.audio.pause();
      });
    } else if (!this.state.isPlaying && this.state.beat === id) {
      this.setState({ isPlaying: true }, function () {
        this.refs.audio.play();
      });
    } else {
      this.setState({ beat: id, isPlaying: true }, function () {
        this.refs.audio.pause();
        this.refs.audio.load();
        this.refs.audio.play();
      });
    }
  };

  render() {
    const play_beats = DATA_BEATS.map((beat) => {
      return (
        <div className={styles.Beats_info} key={beat.key}>
          <div className={styles.album_ctn}>
            <img
              src={beat.img}
              alt={beat.alt}
              className={styles.album_artwork}
              onClick={this.beatPlayerHandler.bind(this, beat.key)}
            />
            <div className={styles.play}>
              {!this.state.isPlaying ? (
                <PlayArrowIcon
                  style={{
                    fill: "white",
                    width: "50%",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={this.beatPlayerHandler.bind(this, beat.key)}
                />
              ) : (
                <PauseIcon
                  style={{
                    fill: "white",
                    width: "50%",
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={this.beatPlayerHandler.bind(this, beat.key)}
                />
              )}
            </div>
          </div>
          <h3>{beat.name}</h3>
        </div>
      );
    });
    play_beats.push(
      <div className={styles.Beats_info} key="100">
        <div className={styles.album_artwork_end}>
          {" "}
          Download the app to see the rest of the beats!
        </div>

        <h3>
          <a href="https://apps.apple.com/us/app/beatstyle-explore-beats-rap/id1379924613">
            Download on iOS
          </a>
        </h3>
        <h4>
          <br></br>
        </h4>
      </div>
    );
    const beat = play_beats.map((p) => p);

    const producers = DATA_PRODUCER.map((prod) => {
      return (
        <div className={styles.producer} key={prod.key}>
          <img src={prod.img} alt={prod.alt} />
          <h3>{prod.name}</h3>
        </div>
      );
    });

    return (
      <div className={styles.Beats} id="Beats">
        <div className={styles.Beats_heading}>BEATS</div>
        <div className={styles.Beats_body}>
          <div className={styles.Beats_playable}>
            <Carousel
              arrowLeft={
                <ArrowBackIosIcon
                  style={{ width: 30, height: 30, fill: "white" }}
                  onClick={() => {
                    this.addArrowClickHandler("l");
                  }}
                />
              }
              arrowRight={
                <ArrowForwardIosIcon
                  style={{ width: 30, height: 30, fill: "white" }}
                  onClick={() => {
                    this.addArrowClickHandler("r");
                  }}
                />
              }
              addArrowClickHandler
              keepDirectionWhenDragging={this.keepDirectionWhenDragging}
              infinite
              draggable={false}
            >
              {beat}
            </Carousel>
          </div>
          {this.state.beat > -1 ? (
            <div style={{ display: "none" }}>
              <audio controls ref="audio" controlsList="nodownload">
                <source
                  key={this.state.beat}
                  src={BEATS[this.state.beat]}
                  type="audio/mp3"
                />
              </audio>{" "}
            </div>
          ) : null}

          <div className={styles.Beats_producers}>
            <h2>OUR PRODUCERS</h2>
            <div className={styles.producers_list}>
              <Carousel
                infinite
                arrowLeft={
                  <ArrowBackIosIcon
                    style={{ width: 30, height: 30, fill: "white" }}
                  />
                }
                arrowRight={
                  <ArrowForwardIosIcon
                    style={{ width: 30, height: 30, fill: "white" }}
                  />
                }
                addArrowClickHandler
              >
                {producers}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Beats;
