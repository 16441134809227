import React from "react";
import styles from "./Home.module.css";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Blackdrop from "../BlackdropBlur/BlackdropBlur";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Record from "../../assets/img/record.png";
import Iphone from "../../assets/img/beatstyle-iphone-home.png";
import IphoneIcon from "../../assets/img/ios-btn-logo.png";
import AndroidIcon from "../../assets/img/android-logo 1.png";
import DownloadBadge from "../../assets/img/Badge_Download_on_App_Store.png";

const Home = props => {
  let blackDrop;
  if (props.open) {
    blackDrop = <Blackdrop click={props.closeBG} />;
  }

  let btn_style = {
    background: "linear-gradient(113.75deg, #4b21b0 0%, #1f006a 100%)"
  };
  if (props.message === "Sent!") {
    btn_style.background =
      "linear-gradient(100.69deg, #0d7b18 0%, #074a0d 100%)";
  }

  return (
    <div className={styles.Home}>
      <Navbar sideBarClickHandler={props.sideBarBtn} show={props.showPopUp} />
      <Sidebar show={props.showSideBar} clicked={props.closeBG} />
      {blackDrop}

      <div className={styles.Home_content}>
        <div className={styles.Home_images}>
          <div className={styles.Home_record_container}>
            <div className={styles.Home_record}>
              <img src={Record} alt="record beats BeatStyle" />
            </div>
            <div className={styles.Home_iphone}>
              <img src={Iphone} alt="beatstyle iphone" />
            </div>
          </div>
          <a href="https://apps.apple.com/us/app/beatstyle-explore-beats-rap/id1379924613">
            <img
              src={DownloadBadge}
              alt="beatsyle download iphone app"
              width="150"
              height="50.1"
              padding="20px"
            />
          </a>
        </div>

        <div className={styles.Home_text}>
          <h1>Mic check 🎤, 1, 2</h1>
          {/* <div className={styles.break}> </div> */}
          <h1>We're ready for you!</h1>
          <h3>Find beats, make tracks, & share with friends.</h3>
          <div className={styles.Home_btn_ctn}>
            <a
              href="https://apps.apple.com/us/app/beatstyle-explore-beats-rap/id1379924613"
              className={styles.iphone_btn}
            >
              <div className={styles.ios_icon}>
                <img src={IphoneIcon} alt="iphone beatstyle" />
              </div>
              <p>Download iOS app</p>
              <div />
            </a>
            <form className={styles.txt_link}>
              <PhoneInput
                disableAreaCodes={true}
                country={"us"}
                value={props.phone}
                onChange={props.handleOnChange}
                inputStyle={{ width: "100%" }}
                containerStyle={{ marginRight: "2%" }}
              />

              <button
                id="phoneNumberBtn"
                type="button"
                className={styles.send_btn}
                onClick={props.submit}
                style={btn_style}
              >
                {props.message}
              </button>
            </form>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeuVO5iqBjybRC9vppQ61ZizK7S-90C5ece3zRdc6OTrJ4Y0g/viewform" className={styles.android_btn}>
              <div className={styles.and_icon}>
                <img src={AndroidIcon} alt="android beatstyle app" />
              </div>
              <p>Join the waitlist</p>
              <div />
            </a>
          </div>
        </div>
        <div className={styles.MOBILE_Home_text}>
          <h3>Send me the link!</h3>
          <form className={styles.txt_link}>
            <PhoneInput
              disableAreaCodes={true}
              country={"us"}
              value={props.phone}
              onChange={props.handleOnChange}
              inputStyle={{ width: "100%" }}
              containerStyle={{ marginRight: "2%" }}
            />

            <button
              id="phoneNumberBtn"
              type="button"
              className={styles.send_btn}
              onClick={props.submit}
              style={btn_style}
            >
              {props.message}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
